import { gql } from "@apollo/client";

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    changePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      jwt
      user {
        id
        username
        email
        confirmed
        blocked
        name
        surname
        birthDate
        sex
        iin
        fullAddress
        role {
          id
          name
          description
          type
        }
      }
    }
  }
`;
