import * as React from "react";

import { injectIntl } from "gatsby-plugin-intl";
import { useContext } from "react";
import "../PersonalCabinet.css";
import parse from "html-react-parser";
import { AppStateContext } from "../../Layout/Layout";
// @ts-ignore
const MyAccount = ({ intl }) => {
  // @ts-ignore
  const { profile } = useContext(AppStateContext);

  return (
    <div className="xl:w-[38rem] lg:w-[34rem] grid lg:grid-cols-2 xs:grid-cols-1">
      <p className="font-medium text-[1rem] mb-[0.5rem]">
        {parse(
          intl.formatMessage({
            id: "Registration.Form.IIN",
          })
        )}
        :<span className="font-bold ml-[0.5rem]">{profile.iin}</span>
      </p>
      <p className="font-medium text-[1rem]  mb-[0.5rem]">
        {parse(
          intl.formatMessage({
            id: "Registration.Form.PhoneNumber",
          })
        )}
        :<span className="font-bold ml-[0.5rem]">+{profile.username}</span>
      </p>
      <p className="font-medium text-[1rem]  mb-[0.5rem]">
        {parse(
          intl.formatMessage({
            id: "Registration.Form.Birthdate",
          })
        )}
        :<span className="font-bold ml-[0.5rem]">{profile.birthDate}</span>
      </p>
      <p className="font-medium lowercase text-[1rem]  mb-[0.5rem]">
        {parse(
          intl.formatMessage({
            id: "Registration.Form.Email",
          })
        )}
        :<span className="font-bold ml-[0.5rem]">{profile.email}</span>
      </p>
      <p className="font-medium text-[1rem]  mb-[0.5rem]">
        {parse(
          intl.formatMessage({
            id: "PersonalCabinet.Gender",
          })
        )}
        :
        <span className="font-bold ml-[0.5rem]">
          {profile.sex === "muzhskoj"
            ? parse(
                intl.formatMessage({
                  id: "Registration.Form.Gender.Man",
                })
              )
            : parse(
                intl.formatMessage({
                  id: "Registration.Form.Gender.Woman",
                })
              )}
        </span>
      </p>
    </div>
  );
};

export default injectIntl(MyAccount);
