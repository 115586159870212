import * as React from "react";
import "./Registration/Registration.css";

// @ts-ignore
import Logo from "../../../images/logo.png";
// @ts-ignore
import SearchIcon from "../../../images/search_icon.svg";
// @ts-ignore
import MenuIcon from "../../../images/menu.svg";

import { injectIntl, navigate } from "gatsby-plugin-intl";
import CrossButton from "../../images/cross_button.svg";
import Eye from "../../images/eye.svg";
import parse from "html-react-parser";
import Popup from "../Popup";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../mutation/changePassword";
import { localStorageSetItem } from "../../service/storage";

interface Values {
  currentPassword: string; // Старый пароль
  password: string; // Новый пароль
  passwordConfirmation: string; // Пароль
}
// @ts-ignore
const PasswordChange = ({ intl, onClose, isOpen }) => {
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD);

  return (
    <Popup
      intl={intl}
      isOpen={isOpen}
      onClose={onClose}
      isModalContentShown={isOpen}
    >
      <div className="flex justify-center items-center mb-[0.4rem] ">
        <div className=" flex flex-col items-center lg:max-w-[1200px] lg:w-[1200px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className="bg-white flex items-center sm:px-[2rem] xs:px-[1rem] flex-col rounded-[1.25rem] sm:w-[24rem] xs:w-[18.5rem] h-auto  border border-black">
            <Formik
              initialValues={{
                currentPassword: "",
                password: "",
                passwordConfirmation: "",
              }}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                changePassword({
                  variables: {
                    ...values,
                  },
                  onCompleted: (data) => {
                    localStorageSetItem("token", data.changePassword.jwt);
                    setSubmitting(false);
                    onClose();
                  },
                  onError: (error) => {
                    console.error(error.message);
                  },
                });
              }}
            >
              <Form>
                <div className="w-[100%]">
                  {/*title*/}
                  <div className="grid grid-flow-col justify-between sm:items-center xs:items-start mt-[2.6rem]">
                    <h3 className="sm:w-auto xs:w-[90%] font-medium text-[1.5rem]">
                      {parse(
                        intl.formatMessage({ id: "ChangePassword.Title" })
                      )}
                    </h3>
                    <img
                      src={CrossButton}
                      alt="Close button"
                      className="cursor-pointer sm:mt-[0rem] xs:mt-[1rem]"
                      onClick={onClose}
                    />
                  </div>

                  {/*inputs*/}
                  <div className="flex flex-col">
                    <div className="flex flex-col   sm:mt-[1.5rem] xs:mt-[1rem] ">
                      <div className="grid sm:grid-flow-row ">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({
                              id: "ChangePassword.OldPassword",
                            })
                          )}
                          <span className="text-red-600">*</span>
                        </p>
                        <Field
                          type="password"
                          id="currentPassword"
                          name="currentPassword"
                          placeholder="Старый пароль"
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem] "
                        />
                        <img src={Eye} alt="" className="eye cursor-pointer" />
                      </div>
                      <div className="grid grid-flow-row">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({
                              id: "ChangePassword.NewPassword",
                            })
                          )}
                          <span className="text-red-600">*</span>
                        </p>
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Новый пароль"
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem] "
                        />
                        <img src={Eye} alt="" className="eye cursor-pointer" />
                      </div>
                      <div className="grid grid-flow-row">
                        <p className="font-medium text-[1rem]">
                          {parse(
                            intl.formatMessage({
                              id: "Registration.Form.RepeatPassword",
                            })
                          )}
                          <span className="text-red-600">*</span>
                        </p>
                        <Field
                          type="password"
                          id="passwordConfirmation"
                          name="passwordConfirmation"
                          placeholder="Повторить пароль"
                          className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem] "
                        />
                        <img src={Eye} alt="" className="eye cursor-pointer" />
                      </div>
                    </div>
                    <p className="font-reguler text-[0.875] text-[#001439] text-opacity-75">
                      {parse(
                        intl.formatMessage({
                          id: "Registration.Form.PasswordDesc",
                        })
                      )}
                    </p>
                  </div>
                </div>

                {/*buttons*/}
                <button
                  type="submit"
                  className="mb-[4rem] sm:mt-[2rem] xs:mt-[1.5rem] sm:w-[16rem] xs:w-[16.5rem] h-[3rem] text-[1.25rem] rounded-[0.56rem] text-white font-semibold bg-[#FF922E]"
                >
                  {parse(intl.formatMessage({ id: "ChangePassword.Button" }))}
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default injectIntl(PasswordChange);
