import * as React from "react";

// @ts-ignore
import Logo from "../../../images/logo.png";
// @ts-ignore
import SearchIcon from "../../../images/search_icon.svg";
// @ts-ignore
import MenuIcon from "../../../images/menu.svg";

import { injectIntl } from "gatsby-plugin-intl";
import CrossButton from "../../images/cross_button.svg";
import parse from "html-react-parser";
import Popup from "../Popup";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
  localStorageSetItem,
  localStorageSetProfile,
} from "../../service/storage";
import DatePickerField from "../DatePickerField";
import MaskedInput from "react-text-mask";
import { UPDATE_PROFILE } from "../../mutation/updateProfile";
import { useMutation, useQuery } from "@apollo/client";
import { useContext } from "react";
import { AppStateContext } from "../Layout/Layout";
import { GET_ANALYSIS_BY_TYPE } from "../../query/getAnalysis";
import { ME } from "../../query/me";
import { phoneNumberMask } from "../../utils/regex";

interface Values {
  name: string;
  surname: string;
  patronymic: string;
  iin: string;
  phone: string;
  birthDate: Date;
  sex: string;
  email: string;
  fullAddress: string;
}

const prepareData = (values: Values) => {
  const birthDate = values.birthDate.toLocaleDateString();
  const preparedData = {
    name: values.name,
    surname: values.surname,
    patronymic: values.patronymic,
    iin: values.iin,
    username: values.phone.replace(/\D/g, ""),
    // birthDate: birthDate,
    sex: values.sex,
    email: values.email,
    fullAddress: values.fullAddress,
  };

  return preparedData;
};
const formatDate = (date: string) => {
  return new Date(date);
};
// @ts-ignore
const EditAccount = ({ intl, onClose, isOpen }) => {
  const { setProfile } = useContext(AppStateContext);

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);

  const { loading: loadingProfile, error, data: profileData } = useQuery(ME);
  console.log({ profileData });

  if (loading) return intl.formatMessage({ id: "Loading.LoadingTitle" });

  if (error) return intl.formatMessage({ id: "Error.ErrorTitle" });

  return (
    <Popup
      intl={intl}
      isOpen={isOpen}
      onClose={onClose}
      isModalContentShown={isOpen}
    >
      <div className="flex justify-center items-center mb-[0.4rem] ">
        <div className=" flex flex-col items-center lg:max-w-[1200px] lg:w-[1200px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className="bg-white flex items-center sm:px-[2rem] xs:px-[1rem] flex-col rounded-[1.25rem] lg:w-[45rem] sm:w-[40rem] xs:w-[18.5rem]  h-auto  border border-black">
            <Formik
              initialValues={{
                name: (profileData && profileData.me.name) || "",
                surname: (profileData && profileData.me.surname) || "",
                patronymic: (profileData && profileData.me.patronymic) || "",
                iin: (profileData && profileData.me.iin) || "",
                phone: (profileData && profileData.me.username) || "",
                birthDate:
                  (profileData && formatDate(profileData.me.birthDate)) || "",
                sex: (profileData && profileData.me.sex) || "",
                email: (profileData && profileData.me.email) || "",
                fullAddress: (profileData && profileData.me.fullAddress) || "",
              }}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                updateProfile({
                  variables: {
                    id: profileData.me.id,
                    data: prepareData(values),
                  },
                  onCompleted: (data) => {
                    localStorageSetProfile({
                      id: data.updateUsersPermissionsUser.data.id,
                      ...data.updateUsersPermissionsUser.data.attributes,
                    });
                    setProfile({
                      id: data.updateUsersPermissionsUser.data.id,
                      ...data.updateUsersPermissionsUser.data.attributes,
                    });
                    setSubmitting(false);
                    onClose();
                  },
                  onError: (error) => {
                    console.error(error.message);
                  },
                });
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <Form>
                    <div className="w-[100%]">
                      {/*title*/}
                      <div className="grid grid-flow-col justify-between sm:items-center xs:items-start mt-[2.6rem]">
                        <h3 className="sm:w-auto xs:w-[90%] font-medium text-[1.5rem]">
                          {parse(
                            intl.formatMessage({
                              id: "PersonalCabinet.EditAccount.Title",
                            })
                          )}
                        </h3>
                        <img
                          src={CrossButton}
                          alt="Close button"
                          className="cursor-pointer sm:mt-[0rem] xs:mt-[1rem]"
                          onClick={onClose}
                        />
                      </div>
                      <div className="w-[100%]">
                        {/*inputs*/}
                        <div className="grid sm:grid-cols-2 xs:flex-col sm:mt-[1rem] xs:mt-[1rem] gap-4">
                          <div className="grid grid-flow-row ">
                            <p className="font-medium text-[1rem]">
                              {parse(
                                intl.formatMessage({
                                  id: "Registration.Form.Surname",
                                })
                              )}
                              <span className="text-red-600 ml-[0.3rem]">
                                *
                              </span>
                            </p>
                            <Field
                              type="text"
                              id="surname"
                              name="surname"
                              placeholder="Фамилия"
                              className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] s:w-[20.5rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                            />
                          </div>
                          <div className="grid grid-flow-row ">
                            <p className="font-medium text-[1rem]">
                              {parse(
                                intl.formatMessage({
                                  id: "Registration.Form.Name",
                                })
                              )}
                              <span className="text-red-600 ml-[0.3rem]">
                                *
                              </span>
                            </p>
                            <Field
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Имя"
                              className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] s:w-[20.5rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                            />
                          </div>
                          <div className="grid grid-flow-row ">
                            <p className="font-medium text-[1rem]">
                              {parse(
                                intl.formatMessage({
                                  id: "Registration.Form.MiddleName",
                                })
                              )}
                              <span className="text-red-600 ml-[0.3rem]">
                                *
                              </span>
                            </p>
                            <Field
                              type="text"
                              id="patronymic"
                              name="patronymic"
                              placeholder="Отчество"
                              className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] s:w-[20.5rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                            />
                          </div>
                          <div className="grid grid-flow-row ">
                            <p className="font-medium text-[1rem]">
                              {parse(
                                intl.formatMessage({
                                  id: "Registration.Form.IIN",
                                })
                              )}
                              <span className="text-red-600 ml-[0.3rem]">
                                *
                              </span>
                            </p>
                            <Field
                              type="text"
                              id="iin"
                              name="iin"
                              placeholder="Ваш ИИН"
                              className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] s:w-[20.5rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                            />
                          </div>
                          <div className="grid grid-flow-row ">
                            <p className="font-medium text-[1rem]">
                              {parse(
                                intl.formatMessage({
                                  id: "Registration.Form.Address",
                                })
                              )}
                              <span className="text-red-600 ml-[0.3rem]">
                                *
                              </span>
                            </p>
                            <Field
                              type="text"
                              id="fullAddress"
                              name="fullAddress"
                              placeholder="Адрес проживания"
                              className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] s:w-[20.5rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                            />
                          </div>
                          <div className="grid grid-flow-row ">
                            <p className="font-medium text-[1rem]">
                              {parse(
                                intl.formatMessage({
                                  id: "Registration.Form.Birthdate",
                                })
                              )}
                              <span className="text-red-600 ml-[0.3rem]">
                                *
                              </span>
                            </p>
                            <DatePickerField
                              name="birthDate"
                              dateFormat="dd-MM-yyyy"
                              // placeholder={Date()}
                              className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] s:w-[20.5rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem] mb-2.5"
                              // className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] w-[15rem] h-[3rem] border rounded-[0.5rem]"
                            />
                            {/*<img src={Calendar} alt="" className="calendar cursor-pointer" />*/}
                          </div>
                        </div>
                        {/*Choose Gender*/}
                        <fieldset className="sm:flex xs:grid xs:grid-cols-1 gap-4">
                          <p className="font-medium text-[1rem]">
                            {parse(
                              intl.formatMessage({
                                id: "Registration.Form.Gender.Title",
                              })
                            )}
                          </p>
                          <div className="flex gap-4">
                            <div className="flex gap-4">
                              <Field
                                type="radio"
                                id="female"
                                name="sex"
                                value="zhenskij"
                                className="application_checkbox peer/online w-[1.25rem] h-[1.25rem] border border-[#001439] border-opacity-25 text-indigo-600"
                              />
                              <label
                                htmlFor="female"
                                className="peer-checked/woman:text-indigo-600"
                              >
                                <p className="font-medium text-[1rem]">
                                  {parse(
                                    intl.formatMessage({
                                      id: "Registration.Form.Gender.Woman",
                                    })
                                  )}
                                </p>
                              </label>
                            </div>
                            <div className="flex gap-4">
                              <Field
                                type="radio"
                                id="male"
                                name="sex"
                                value="muzhskoj"
                                className="peer/cash w-[1.25rem] h-[1.25rem] border border-[#001439] border-opacity-25 text-indigo-600"
                              />
                              <label
                                htmlFor="male"
                                className="peer-checked/man:text-indigo-600"
                              >
                                <p className="font-medium text-[1rem]">
                                  {parse(
                                    intl.formatMessage({
                                      id: "Registration.Form.Gender.Man",
                                    })
                                  )}
                                </p>
                              </label>
                            </div>
                          </div>
                        </fieldset>

                        {/*inputs*/}
                        <div className="flex flex-col">
                          <div className="grid sm:grid-cols-2 xs:flex-col sm:mt-[1.5rem] xs:mt-[1rem] gap-4">
                            <div className="grid sm:grid-flow-row ">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "Registration.Form.Email",
                                  })
                                )}{" "}
                                <span className="text-red-600 ml-[0.3rem]">
                                  *
                                </span>
                              </p>
                              <Field
                                type="email"
                                id="email"
                                name="email"
                                placeholder="E-mail"
                                className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] s:w-[20.5rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                              />
                            </div>
                            <div className="grid grid-flow-row ">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "Registration.Form.PhoneNumber",
                                  })
                                )}
                                <span className="text-red-600 ml-[0.3rem]">
                                  *
                                </span>
                              </p>
                              <Field
                                name="phone"
                                render={({ field }) => (
                                  <MaskedInput
                                    {...field}
                                    mask={phoneNumberMask}
                                    showMask="true"
                                    id="phone"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="pl-[1rem] px-[0.8rem] mt-[0.875rem] w-auto h-[3rem] border rounded-[0.5rem]"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*buttons*/}
                    <button
                      type="submit"
                      className="mb-[4rem] sm:mt-[2rem] xs:mt-[1.5rem] sm:w-[16rem] xs:w-[16.5rem] h-[3rem] text-[1.25rem] rounded-[0.56rem] text-white font-semibold bg-[#FF922E]"
                    >
                      {parse(
                        intl.formatMessage({
                          id: "PersonalCabinet.EditAccount.SaveButton",
                        })
                      )}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default injectIntl(EditAccount);
