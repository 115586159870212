import React, { useState } from "react";
import { Router } from "@reach/router";
import Layout from "../components/Layout";
import PrivateRoute from "../components/privateRoute";
import Login from "../components/Login";
import { injectIntl } from "gatsby-plugin-intl";
import HistoryApplicationDetail from "../components/personalCabinet/HistoryApplication/HistoryApplicationDetail";
// import NewPassword from "../components/newPassword/NewPassword";
import BreadCrumbs from "../BreadCrumbs";
import Results from "../components/personalCabinet/Results/Results";
import HistoryApplication from "../components/personalCabinet/HistoryApplication/HistoryApplication";
// import FamilyAccount from "../components/personalCabinet/FamilyAccount/FamilyAccount";
import MyAccount from "../components/personalCabinet/MyAccount/MyAccount";
import PersonalCabinetSidebar from "../components/personalCabinet/PersonalCabinetSidebar";
import { AppStateContext } from "../components/Layout/Layout";

// @ts-ignore
const Personal = ({ intl, pageContext }) => {
  // @ts-ignore
  const [showAnalysisTitle, setShowAnalysisTitle] =
    useState(`HistoryApplication`);

  return (
    <Layout>
      <>
        <div className="flex items-center justify-center bg-[#FAFAFA]">
          <div className="flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
            <div className=" mb-10 flex flex-col justify-start ">
              <div className="mt-[2.5rem] mb-[2.5rem]">
                <BreadCrumbs
                  pageContext={pageContext}
                  title={"Личная страница"}
                />
              </div>
              <AppStateContext.Consumer>
                {({ profile }) => {
                  if (profile) {
                    return (
                      <div className="w-[100%] flex   gap-16 mb-[2.5rem]">
                        <h2 className="text-[2rem] text-[#001439] font-semibold ">
                          {profile.name} {profile.surname}
                        </h2>
                      </div>
                    );
                  }
                }}
              </AppStateContext.Consumer>

              <div className="w-[100%] flex  justify-between gap-10 xs:flex-col sm:flex-col lg:flex-row">
                <PersonalCabinetSidebar
                  setShowAnalysisTitle={setShowAnalysisTitle}
                  showAnalysisTitle={showAnalysisTitle}
                />
                <Router>
                  {/*@ts-ignore*/}
                  <PrivateRoute
                    component={HistoryApplicationDetail}
                    path="/:lang/personal/history/:orderNumber"
                  />
                  {/*@ts-ignore*/}
                  {/*<PrivateRoute*/}
                  {/*  component={NewPassword}*/}
                  {/*  path="/:lang/personal/new-password"*/}
                  {/*/>*/}
                  {/*@ts-ignore*/}
                  <PrivateRoute
                    component={Results}
                    path="/:lang/personal/results"
                  />
                  {/*@ts-ignore*/}
                  <PrivateRoute component={Results} path="/:lang/personal/" />
                  {/*@ts-ignore*/}
                  <PrivateRoute
                    component={HistoryApplication}
                    path="/:lang/personal/history"
                  />
                  {/*@ts-ignore*/}
                  {/*<PrivateRoute*/}
                  {/*  component={FamilyAccount}*/}
                  {/*  path="/:lang/personal/family-account"*/}
                  {/*/>*/}
                  {/*@ts-ignore*/}
                  <PrivateRoute
                    component={MyAccount}
                    path="/:lang/personal/my-account"
                  />
                  {/*@ts-ignore*/}
                  <Login intl={intl} path="/:lang/?setShowLoginPopup=true" />
                </Router>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default injectIntl(Personal);
