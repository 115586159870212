import parse from "html-react-parser";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";

type PeriodProps = {
  title: string;
  setPeriod: Dispatch<SetStateAction<string>>;
  onClose: () => void;
  intl: any;
};

const Period: FC<PeriodProps> = ({ title, setPeriod, intl, onClose }) => (
  <li
    className=" font-regular text-[1.125rem] cursor-pointer mb-1"
    onClick={() => {
      setPeriod(title);
      onClose();
    }}
  >
    {parse(
      intl.formatMessage({
        id: title,
      })
    )}
  </li>
);

export default Period;
