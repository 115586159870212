import * as React from "react";
import "./Results.css";
import { injectIntl } from "gatsby-plugin-intl";
import { useContext, useState } from "react";
import "../PersonalCabinet.css";
import parse from "html-react-parser";
import ResultsItem from "./ResultsItem";
import ArrowDown from "../../../images/arrow_down.svg";
import ArrowUp from "../../../images/arrow_up.svg";
import Search from "../../../images/search.svg";
import Pagination from "../../Pagination";
import { periodOfResults } from "../../../utils/period";
import Period from "./Period";
import { AppStateContext } from "../../Layout/Layout";
// @ts-ignore

const Results = ({ intl }) => {
  // @ts-ignore
  const { profile } = useContext(AppStateContext);
  const [showDropdownMenu, setShowDropDownMenu] = useState(false);
  const [period, setPeriod] = useState("PersonalCabinet.Filter.ForAllPeriod");

  return (
    <div className="flex flex-col">
      <div className="flex flex-col ">
        <div className="xl:w-[48rem] lg:w-[36rem] xs:w-auto relative flex sm:flex-row xs:flex-col justify-between sm:items-center xs:items-start">
          {/*//ToDo ArrowUp/ArrowDown будет меняться в зависимости от открыли выпадающий список или нет */}
          <div className="cursor-pointer  z-[1045] w-auto flex items-start px-[1rem]  border border-opacity-25 border-[#618EE4] bg-white rounded-[0.5rem]">
            <div className="flex py-[1rem]">
              <p className="font-regular text-[1.125rem]">
                {profile.name} {profile.surname}
              </p>
              <img src={ArrowDown} alt="" className=" ml-[1rem] w-[0.6rem]" />
            </div>
          </div>
          {/*//Todo бэкграунд калор ( bg-[#618EE4] bg-opacity-10) будет меняться в зависимости от открыли выпадающий список или нет */}
          <div className="flex flex-col  sm:mt-[0rem] xs:mt-[1rem]">
            <div
              className=" cursor-pointer flex items-center px-[1rem] w-[13rem] h-[3rem]  rounded-[0.5rem] bg-[#618EE4] bg-opacity-10"
              onClick={() => setShowDropDownMenu(!showDropdownMenu)}
            >
              <p className="font-regular text-[1.125rem]">
                {parse(
                  intl.formatMessage({
                    id: period,
                  })
                )}
              </p>
              {showDropdownMenu ? (
                <img
                  src={ArrowUp}
                  alt="Arrow Up"
                  className="ml-[1rem] w-[0.6rem]"
                />
              ) : (
                <img
                  src={ArrowDown}
                  alt="Arrow Down"
                  className="ml-[1rem] w-[0.6rem]"
                />
              )}
            </div>
            <ul
              className={`w-[13rem] mt-[3.5rem] flex flex-col pl-[1rem] py-[1rem] z-20 absolute justify-center bg-white border border-[#618EE4] border-opacity-25 rounded-[0.56rem] shadow-md dropdown-menu ${
                showDropdownMenu ? `aactive` : `inactive`
              }`}
            >
              {periodOfResults.map((period, index) => (
                <Period
                  key={`results-${index}`}
                  title={period}
                  setPeriod={setPeriod}
                  onClose={() => setShowDropDownMenu(false)}
                  intl={intl}
                />
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-[1rem]">
          <input
            type="text"
            className="border border-2 rounded-[0.6rem] xl:w-[33rem] lg:w-[26rem] sm:w-[33rem] xs:w-[18rem] h-[3rem] pl-[1rem]"
            placeholder="Поиск по анализам"
          />
          <img src={Search} alt="" className="search" />
        </div>
      </div>

      <ResultsItem />

      <Pagination />
    </div>
  );
};

export default injectIntl(Results);
