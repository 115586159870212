import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
  mutation updateProfile($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
        attributes {
          username
          email
          confirmed
          blocked
          name
          surname
          birthDate
          sex
          iin
        }
      }
    }
  }
`;
