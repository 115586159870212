import React from "react";
import { navigate } from "gatsby-plugin-intl";
import { localStorageGetItem } from "../service/storage";

//@ts-ignore
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (
    (localStorageGetItem("token") === null || undefined) &&
    location.pathname !== "/?setShowLoginPopup=true"
  ) {
    navigate("/?setShowLoginPopup=true");
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
