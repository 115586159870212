import * as React from "react";

import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import QR from "../../../images/QR_code.svg";
import Download from "../../../images/download_white.svg";
import Receipt from "../../../images/receipt.svg";
// @ts-ignore
const ResultsItem = ({ intl }) => (
  <div className="mb-[1rem] flex flex-col xs:flex-col bg-white border border-[#618EE4] border-opacity-25 rounded-[1rem] xl:w-[48rem] lg:w-[36rem] xs:w-auto px-[1.5rem] py-[1.5rem]  mt-[1rem]">
    <div className="flex sm:flex-row  justify-between">
      <div className="w-[70%] text-[#001439] ">
        <img src={Receipt} alt="" className="" />
        <h4 className="pt-[1rem] font-semibold text-[1.25rem]">
          Общий анализ крови
        </h4>
        <p className="pt-[1rem] font-medium text-[1rem] ">
          {parse(
            intl.formatMessage({
              id: "PersonalCabinet.Application",
            })
          )}
          :<span className="font-semibold ml-[0.5rem]">№1234567</span>
        </p>

        <p className="pt-[1rem] font-medium text-[1rem]">
          {parse(
            intl.formatMessage({
              id: "PersonalCabinet.ResultsFrom",
            })
          )}
          :<span className="font-semibold ml-[0.5rem]">08.08.2022 11:00</span>
        </p>
        <button className="mt-[1rem] flex items-center justify-center bg-[#618EE4] rounded-[0.56rem] h-[2.75rem] px-[1rem] py-[0.6rem]">
          <img src={Download} alt="" className="" />
          <p className="font-semibold text-[1rem] text-white pl-[0.5rem]">
            {parse(
              intl.formatMessage({
                id: "PersonalCabinet.DownloadButton",
              })
            )}
          </p>
        </button>
      </div>
      <div className="sm:w-[20%] xs:w-[30%] sm:ml-[0rem] xs:-ml-[1rem]">
        <img src={QR} alt="" className="" />
      </div>
    </div>
  </div>
);

export default injectIntl(ResultsItem);
