import * as React from "react";

import { injectIntl, navigate } from "gatsby-plugin-intl";
import { useContext, useState } from "react";
import "../PersonalCabinet.css";
import parse from "html-react-parser";
// @ts-ignore
import Edit from "../../../images/edit_blue.svg";
import UserInfo from "./UserInfo";
import EditAccount from "../../popups/EditAccount";
import { AppStateContext } from "../../Layout/Layout";
import PasswordChange from "../../popups/PasswordChange";

// @ts-ignore
const MyAccount = ({ intl }) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showPwdPopup, setShowPwdPopup] = useState(false);

  // @ts-ignore
  const { profile } = useContext(AppStateContext);

  if (!profile) {
    navigate("/");
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col ">
        <div className="bg-white py-[2rem] px-[1.5rem] border border-[#618EE4] border-opacity-25 rounded-[1rem] xl:w-[48rem] lg:w-[36rem] xs:w-auto  flex sm:flex-row xs:flex-col justify-between">
          <div className="flex flex-col">
            <div className="xl:w-[45rem] lg:w-[34rem] sm:w-[40rem] flex sm:flex-row xs:flex-col lg:items-center sm:items-start justify-between   mb-[2rem] ">
              <h2 className="xl:w-[100%] lg:w-[70%] sm:w-[50%] xl:text-[2rem] lg:text-[1.5rem] xs:text-[2rem] text-[#001439] font-semibold ">
                {profile.name} {profile.surname}
              </h2>

              <div
                className=" sm:w-[30%] flex cursor-pointer items-center lg:mt-[0rem] xs:mt-[1rem]"
                onClick={() => setShowEditPopup(true)}
              >
                <img src={Edit} alt="Edit" className="" />
                <p className="text-[1rem] font-semibold text-[#618EE4] ml-[0.5rem]">
                  {parse(
                    intl.formatMessage({
                      id: "PersonalCabinet.EditButton",
                    })
                  )}
                </p>
              </div>
            </div>
            <UserInfo />
          </div>
        </div>
        <div className="bg-white mt-[1.25rem] py-[2rem] px-[1.5rem] border border-[#618EE4] border-opacity-25 rounded-[1rem] xl:w-[48rem] lg:w-[36rem] xs:w-auto  flex sm:flex-row xs:flex-col justify-between">
          <div className="flex flex-col">
            <div className="xl:w-[46rem] lg:w-[35rem] sm:w-[40rem] flex items-center justify-between  ">
              <h4 className="xl:w-[100%] lg:w-[70%] sm:w-[50%] text-[1.25rem] text-[#001439] font-semibold ">
                {parse(
                  intl.formatMessage({
                    id: "Login.Password",
                  })
                )}
              </h4>

              <p
                className="text-[1rem] font-semibold text-[#618EE4] lg:w-[30%] sm:w-[27%]  cursor-pointer "
                onClick={() => setShowPwdPopup(true)}
              >
                {parse(
                  intl.formatMessage({
                    id: "PersonalCabinet.ChangePassword",
                  })
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <EditAccount
        isOpen={showEditPopup}
        onClose={() => setShowEditPopup(false)}
      />
      <PasswordChange
        isOpen={showPwdPopup}
        onClose={() => setShowPwdPopup(false)}
      />
    </div>
  );
};

export default injectIntl(MyAccount);
